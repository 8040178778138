<template>
  <div class="home bg-light pb-5">
    <img src="https://eventcube-custom-stores.s3.eu-west-1.amazonaws.com/cop27/header.png" width="100%" class="header-desktop">
    <img src="https://eventcube-custom-stores.s3.eu-west-1.amazonaws.com/cop27/header-mobile.png" width="100%" class="header-mobile">

    <div class="container pt-4 pb-4">
      <router-link to="/lobby" :style="{'color':bc.branding.colourTextHeading}" ><b>Back to Lobby</b></router-link>
     
      <h4 class="mt-4 text-center mb-2">{{stage.title}}</h4>
      <p class="text-center">{{stage.description}}</p>
      <div class="row justify-content-center mt-4">
        <div class="col-md-8">
          <div class="mb-2 language-btns-wrap" v-if="stage.id == 'main-stage'">
            <b class="lang-title">Language:</b>
            <a class="btn language-btn mx-2 rounded-0"  v-bind:class="{ 'active' : activeLanguage === 'en' }" v-on:click="setLanguage('en')">English</a>
            <a class="btn language-btn rounded-0" v-bind:class="{ 'active' : activeLanguage === 'fr' }" v-on:click="setLanguage('fr')">Francais</a>
          </div>
          <div class="video-wrapper">
          <div id="aws_player" class="video-player-wrapper" style="height: 100%;">
            <iframe v-if="activeLanguage === 'en'" width="560" height="315" :src="stage.stream_source" title="YouTube video player" frameborder="0" allow="allowfullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            <iframe v-if="activeLanguage === 'fr'" width="560" height="315" :src="stage.stream_source_fr" title="YouTube video player" frameborder="0" allow="allowfullscreen; accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          </div>

        </div>
        <div class="col-md-4" v-if="showChat">
          <iframe
            :src="chatUrl"
            style="border: none; width: 100%; height: 100%"
            allowTransparency="true"
            class="chat-iframe"
          ></iframe>
          
        </div>
        <div>
        <div class="col-md mb-2" v-if="questionSubmited">
          <div class="border text-center p-3 lt-lg-5">
            <h4 class="mb-2" :style="{ color: bc.branding.colourTextHeading }">
              Question submited!
            </h4>
            <button
              type="submit"
              class="btn btn-success rounded-0"
              @click="questionSubmited = false"
            >
              Close
            </button>
          </div>
        </div>
        </div>
      </div>
    </div>
   
      <!----

              SCHEDULE UNIT (TABLE)

      ----->

      <div class="schedule-unit-wrapper container mt-3 " v-if="bc.schedule && bc.schedule.scheduleType == 'table'">
        <div class="primary-top-extra"></div>
        <div class="container schedule-unit">
          <div class="row p-2 pt-3 p-md-5 border justify-content-between">
            <div class="col-md-3">
              <h2 :style="{'color':bc.branding.colourTextHeading}" class="my-2">{{bc.schedule.scheduleTitle}}</h2>
              <p class="small" v-html="bc.schedule.scheduleSubtitle"></p>
              <p class="mt-3"><router-link to="/speakers" class="btn btn-primary rounded-0 text-white" >View Speakers</router-link></p>
            </div>
            <div class="col-md-8" >
              <div v-for="scheduleItem in bc.schedule.scheduleItems" :key="scheduleItem.dateTime">
                <div class="row">
                  <div class="col-1">
                      <p :style="{'color':bc.branding.colourTextHeading}" class="pr-1 small mt-0">{{ scheduleItem.dateTime }}</p>
                  </div>
                  <div class="col">
                 <h6 :style="{'color':bc.branding.colourTextHeading}"> {{scheduleItem.title}}</h6>
                  <p class="small mt-1">{{scheduleItem.description}}</p>
                  <table class="table">
                      <tr v-for="speakerItem in scheduleItem.speakers" :key="speakerItem.title">
                        <td :style="{'color':bc.branding.colourPrimary}">{{speakerItem.time}}</td>
                        <td><p class="mb-0" :style="{'color':bc.branding.colourTextHeading}"><b>{{speakerItem.title}}</b></p><p class="my-0"><small>{{speakerItem.shortDescription}}</small></p></td>
                      </tr>
                  </table>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <div class="bg-py-5" v-if="stage.live && bc.schedule && bc.schedule.scheduleType == 'splitDay'">
        <h4 class="text-center mt-5 mb-3">Live Stream Schedule</h4>
        <div class="schedule-unit-wrapper container" v-for="scheduleItem in bc.schedule.scheduleItems" :key="scheduleItem.dateTime">
          <div class="container mt-3 schedule-unit">
            <div class="row p-2 pt-3 p-md-3 border justify-content-between bg-white"  v-on:click="scheduleItem.expand = !scheduleItem.expand">
              <div class="col-md-2">
                <h4 :style="{'color':bc.branding.colourTextHeading}" class="mt-0 mb-0"><b>{{scheduleItem.title}}</b></h4>
                <p class="mt-1"  v-if="scheduleItem.expand || scheduleItem.date == dateToday">{{ scheduleItem.dateTime }}</p>
              </div>
              <div class="col-md-9">
                <div class="mt-4" v-if="scheduleItem.expand || scheduleItem.date == dateToday">
                  <h5 :style="{'color':bc.branding.colourPrimary}" class="mt-0 mb-1">Sessions</h5>
                  <div class="row mb-2">
                    <div class="col-6 col-md-3" v-for="speakerItem in scheduleItem.speakers" :key="speakerItem.title" width="100%">
                      <p class="small mt-1 mb-0">{{speakerItem.time}}</p>
                      <h6 class="mb-0 mt-0">{{speakerItem.title}}</h6>
                      <p class="small mt-0">{{speakerItem.shortDescription}}</p>
                    </div>
                  </div>
                  <div class="mt-4 text-right" v-if="scheduleItem.expand || scheduleItem.date == dateToday">
                  <a style="color:#BC8B2C;cursor:pointer;font-size:0.7em;font-weight:bold;text-decoration:underline;" class="mt-0 mb-1">HIDE SCHEDULE</a>
                </div>
                </div>
                <div class="mt-4 text-right" v-if="!scheduleItem.expand && scheduleItem.date != dateToday">
                  <a style="color:#BC8B2C;cursor:pointer;font-size:0.7em;font-weight:bold;text-decoration:underline;" class="mt-0 mb-1">SHOW SCHEDULE +</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div
      class="schedule-unit-wrapper container"
      v-if="bc.schedule && !bc.schedule.scheduleType"
    >
      <div class="container my-3 schedule-unit">
        <div class="row p-2 pt-3 p-md-3 border justify-content-between">
          <div class="col-md-2">
            <h3 :style="{ color: bc.branding.colourTextHeading }" class="my-2">
              {{ bc.schedule.scheduleTitle }}
            </h3>
            <p>{{ bc.schedule.scheduleSubtitle }}</p>
          </div>
          <div class="col-md-9">
            <div
              v-for="scheduleItem in bc.schedule.scheduleItems"
              :key="scheduleItem.dateTime"
            >
              <h4 :style="{ color: bc.branding.colourPrimary }" class="mt-0">
                {{ scheduleItem.dateTime }}<br />
                {{ scheduleItem.title }}
              </h4>
              <div class="row mb-4">
                <div
                  class="col-6 col-md-3"
                  v-for="speakerItem in scheduleItem.speakers"
                  :key="speakerItem.title"
                  width="100%"
                >
                  <img
                    :src="speakerItem.thumbnail"
                    :alt="speakerItem.alt"
                    width="100%"
                  />
                  <h6 class="mt-3 mb-2">{{ speakerItem.title }}</h6>
                  <p class="small mt-0">{{ speakerItem.shortDescription }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 ">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 col-md text-center"
            v-for="sponsorItem in bc.partners"
            :key="sponsorItem.name"
          >
            <img
              :src="sponsorItem.image"
              :alt="sponsorItem.name"
              width="80%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 ">
      <h4 class="text-center">Sponsors</h4>
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 col-md text-center"
            v-for="sponsorItem in bc.sponsors"
            :key="sponsorItem.name"
          >
            <img
              :src="sponsorItem.image"
              :alt="sponsorItem.name"
              width="80%"
            />
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-center mt-4">Contact Us</h4>
    <p class="text-center"><a href="mailto:s.borrini@afdb.org"  :style="{ color: bc.branding.colourTextHeading }" >Email s.borrini@afdb.org</a></p>

    <h4 class="text-center">Useful Links</h4>
    <p class="text-center"><a href="https://www.afdb.org/en/cop27"  :style="{ color: bc.branding.colourTextHeading }" >African Development Bank @COP27</a></p>
  </div>
</template>

<script>
import { sendForm } from "../services/formbackend";

export default {
  name: "Stage",
  data() {
    return {
      voteOptions: ["A", "B", "C", "D"],
      userQuestion: "",
      selected: "",
      activeLanguage: "en",
      voteSubmited: null,
      questionSubmited: null,
      showWidgets: true
    };
  },
  computed: {
    bc() {
      return this.$store.state.bc;
    },
    dateToday() {
      let dateToday = new Date()
      return dateToday.toISOString().split('T')[0]
    },
    stage() {
      return this.$store.getters.getRoomById(this.$route.params.id);
    },
    showChat() {
      return this.stage.chat;
    },
    streamURL() {
      console.log(this.stage.stream_source);
      return this.stage.stream_source;
    },
    holdingVideo() {
      return this.stage.holdingVideo;
    },
    chatUrl() {
      if (this.stage.chatOverride) {
        return (
          "https://minnit.chat/" +
          this.stage.chatOverride +
          "?embed&amp;nickname=" +
          this.$store.state.attendeeName
        );
      }
      return (
        'https://minnit.chat/Eventcube43678?embed'
      );
    },
    captionIframe() {
      if (!this.stage.streamText) {
        return false;
      }
      return (
        "//www.streamtext.net/player/?event=" +
        this.stage.streamText +
        "&chat=false&header=false&fgc=000000&footer=false&ff=Arial&fs=20&indicator=false"
      );
    },
  },
  components: { },
  mounted: function () {},
  watch: {},
  methods: {
    detectFormSubmit(e) {
      if (e.keyCode === 13) {
        this.submitQuestion();
      }
    },
    async submitQuestion() {
      this.$gtag.event("widget", { method: "Question" });
      if (!this.userQuestion) return;
      const formId = "0270ed0f04b52f5b";
      const payload = {
        question: this.userQuestion,
        stage: this.stage.title,
        name: this.$store.state.attendeeName,
      };
      try {
        await sendForm({ formId, payload });
        this.questionSubmited = true;
        this.userQuestion = "";
      } catch (error) {
        this.questionSubmited = false;
      }
    },
    async submitVote() {
      this.$gtag.event("widget", { method: "Vote" });
      if (!this.selected) return;
      const formId = "d89d19de091023ff";
      const payload = {
        vote: this.selected,
        stage: this.stage.title,
        name: this.$store.state.attendeeName,
      };
      try {
        sendForm({ formId, payload });
        this.voteSubmited = true;
        this.selected = "";
      } catch (error) {
        this.voteSubmited = false;
      }
    },
    optionSelected(option) {
      this.selected = option;
    },
    setRoomUrl() {},
    setLanguage(lang) {
      this.activeLanguage = lang;
    },
  },
};
</script>

<style lang="scss">
.language-btn {
  background:#eee;
  font-weight:bold;
  border:1px solid #ccc;
  padding:10px 40px;
}
.language-btn.active {
  background:#BC8B2C;
  color:white !important;
  border:1px solid #BC8B2C;

}

.captionIframe {
  background: #f5f5f5;
  iframe {
    width: 100%;
    border: none;
  }
}

.video-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.form-check.form-check-inline {
  background-color: transparent;
  border: gray 1px solid;
  color: gray;
  padding: 0.5rem;
  width: 40px;
  height: 40px;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  input {
    display: none;
  }
  &.selected {
    background-color: #2b3991;
    color: white;
  }
}

@media only screen and (max-width: 600px) {
  .chat-iframe {
    height: 500px !important;
    margin-top: 25px;
  }

  .lang-title {
    display:block;
    text-align: center;
    padding-bottom:10px;
  }

  .language-btn {
    width:40%;
    display:inline-block;
  }

  .language-btns-wrap {
    text-align:center;
  }

}
</style>
