import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    bc: broadcastConfig,
    eventId: eventId,
    ticketId: ticketId,
    orderTickets: orderTickets,
    attendeeName: attendeeName
  },
  mutations: {

  },
  getters: {
    playerRooms: state => {
      return state.bc.rooms.filter(room => room.type === 'player')
    },
    networkingRooms: state => {
      return state.bc.rooms.filter(room => room.type === 'networking')
    },
    zoomRooms: state => {
      return state.bc.rooms.filter(room => room.type === 'zoom')
    },
    assignedZoomRooms: state => {
      var zoomRooms = state.bc.rooms.filter(room => room.type === 'zoom');
      /* 
      if the user is just assigned a single ticket, 
      they're not assigned any individual zoom rooms, so just return all for the view-only experience.
      */
      if (state.orderTickets.length <= 1 || state.orderTickets.includes('aaa-pass')){
        return state.bc.rooms.filter(room => room.type === 'zoom')
      }

      /*
        Otherwise, they've been assigned multiple tickets which means (in BC+), that they've been assigned rooms.
        Need to document this better, this is a use case on Uber but hopefully generic enough for future projects.
      */
      var zoomRoomsFiltered = [];

      zoomRooms.forEach(zoomRoom => {
          if(state.orderTickets.includes(zoomRoom.showJoinZoomForTicket) || state.orderTickets.includes(zoomRoom.showQuestionForTicket) ) {
            zoomRoomsFiltered.push(zoomRoom);
          }
      });

      return zoomRoomsFiltered;

      return [];
    },
    getRoomById: (state) => (id) => {
      return state.bc.rooms.find(room => room.id === id)
    }
  }
})
