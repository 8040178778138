<template>
  <div class="home">
    <section class="bg-light py-5 mb-3">
      <div class="container">
        <router-link to="/lobby" :style="{'color':bc.branding.colourPrimary}" >&laquo; Back to Lobby</router-link>

        <h4 :style="{'color':bc.branding.colourTextHeading}" class="text-center mt-2 mb-5">Our Speakers</h4>
        <div class="row">
            <div class="col-6 mb-3 speaker col-md-3" v-for="speaker in bc.speakers" :key="speaker.name" >
                <img :src="speaker.image" :alt="speaker.name" width="100%">
                <h4 :style="{'color':bc.branding.colourPrimary}" class="mt-2">{{speaker.name}}</h4>
                <h5 :style="{'color':bc.branding.colourTextHeading}" >{{speaker.subtitle}}</h5>
                <p class="small mb-1" v-html="speaker.description"></p>
                <p><a :href="speaker.link" class="small" target="_blank" :style="{'color':bc.branding.colourPrimary}" >Connect with {{speaker.name}}</a></p>
            </div>
        </div>
      </div>
    </section>
    </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Speakers',
  components: {
  },
  computed: {
    bc() {
      return this.$store.state.bc
    }
  },
  mounted: function () {

  },
}
</script>

<style lang="scss">
    .speaker {
        img {
            max-width:130px;
        }
    }
</style>
