<template>
  <div id="aws_player" class="video-player-wrapper" style="height: 100%;">
    <iframe width="560" height="315" :src="streamUrl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
</template>

<script>
export default {
  name: "AwsPlayer",
  props: {
  },
  data: () => ({
  })
};
</script>

<style lang="scss" scoped>
svg:not([fill]) {
  display: none;
}
</style>