<template>
  <div class="home">
        <img src="https://eventcube-custom-stores.s3.eu-west-1.amazonaws.com/cop27/header.png" width="100%" class="header-desktop">
    <img src="https://eventcube-custom-stores.s3.eu-west-1.amazonaws.com/cop27/header-mobile.png" width="100%" class="header-mobile">

    <section class="bg-light py-3 mb-3">
      
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-md-5 d-flex align-items-center">
            <div class="w-100">
              <div v-if="bc.lobby.introHtml" v-html="bc.lobby.introHtml"></div>
              <h1 :style="{'color':bc.branding.colourTextHeading}" class="animate__animated animate__fadeInDown">{{bc.lobby.title}}</h1>
              <h4 class="mt-4 main-subtitle">{{bc.lobby.subTitle}}</h4>
              <p class="mt-4" :style="{ 'color': bc.branding.colourTextHeading }"><span v-html="bc.lobby.dateTitle"></span></p>
              <div class="text-center text-md-left mb-3">
                <p class="mt-5 small">{{bc.lobby.sponsorTitle}}</p>
                <div>
                  <img :src="sponsor.image" :alt="sponsor.title" v-for="sponsor in bc.lobby.sponsorItems" :key="sponsor.title" class="mr-4" height="50">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="playerRooms.length == 0">
            <img :src=bc.lobby.heroTile class="hero-tile">
          </div>
          <div class="col-md-6" v-if="playerRooms.length === 0">
            <div class="bg-white"  v-for="playerRoom in playerRooms" :key="playerRoom.id">
              <div class="bg-dark primary-top-extra text-right p-1">
                <div class="bg-danger text-center px-2 d-inline-block pulse live-badge text-white"><b>LIVE NOW</b></div>
              </div>
              <div class="">
                <router-link
                  :to="{ name: 'Stage', params: { id: playerRoom.id } }">
                <img :src="playerRoom.thumbnail" width="100%" alt="">
                </router-link>
              </div>
              <div class="p-4 border">
                <h4 :style="{'color':bc.branding.colourTextHeading}"><b>{{ playerRoom.title }}</b></h4>
                <p class="small">{{ playerRoom.description }}</p>
                <hr>
                <div class="text-right">
                  <router-link
                  :to="{ name: 'Stage', params: { id: playerRoom.id } }"
                  class="btn btn-primary rounded-0 text-black"
                  :style="{ 'background-color': bc.branding.highlightColor, 'border-color': bc.branding.highlightColor }"
                  >Watch Now</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container">
        <h4 class="text-center mb-5">Pick a channel to watch</h4>
        <div class="row justify-content-center">
          <div class="col-md-6"  v-for="playerRoom in playerRooms" :key="playerRoom.id">
            <div class="bg-white">
              <div class="bg-dark text-right p-1">
                <div class="bg-danger d-inline-block pulse live-badge"  v-if="playerRoom.live">LIVE NOW</div>
                <div class="bg-info d-inline-block rewatch-badge"  v-if="!playerRoom.live">REWATCH</div>
              </div>
              <div class="">
                <router-link
                  :to="{ name: 'Stage', params: { id: playerRoom.id } }">
                <img :src="playerRoom.thumbnail" width="100%" alt="Thumbnail image of the live stream. Click to play.">
                </router-link>
              </div>
              <div class="p-4">
                <h4 :style="{'color':bc.branding.colourTextHeading}"><b>{{ playerRoom.title }}</b></h4>
                <p class="small">{{ playerRoom.description }}</p>
                <hr>
                <div class="text-right">
                  <router-link
                  :to="{ name: 'Stage', params: { id: playerRoom.id } }"
                  style="color:white;background:rgb(188, 139, 44) !important;border-color:rgb(188, 139, 44) !important;"
                    class="btn btn-success rounded-0 text-black"
                  >Watch now</router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!----

              NETWORKING UNIT

      ----->

      <div class="container mt-5 text-center pt-4 pb-4" v-if="networkingRooms.length > 0">
        <h4 :style="{'color':bc.branding.colourTextHeading}" style="margin:auto;">{{bc.lobby.networkingTitle}}</h4>
      </div>
      <div class="container">
        <div class="row no-gutters justify-content-center">
          <div class="col-6 col-md-3" v-for="networkingRoom in networkingRooms" :key="networkingRoom.id" >
            <div class="bg-white border mb-md-5  mx-2">
              <router-link
                :to="{ name: 'Room', params: { id: networkingRoom.id } }"
              >
              <div class="primary-top-extra"></div>
              <img :src="networkingRoom.thumbnail" alt="Thumbnail of the zoom room. Click to join." width="100%" class="primary-top" :style="{'border-color':bc.branding.colourPrimary}">
              </router-link>
              <div class=" p-3 ">
              <div style="min-height:160px;">
                <h6 class="mb-1">{{networkingRoom.title}}</h6>
                <p class="small mt-2">{{networkingRoom.description}}</p>
              </div>

              <p v-if="networkingRoom.joinFrom" class="text-center small"><b>Join From</b><br> {{networkingRoom.joinFrom}}</p>
              <p class="text-center mb-0">
              <router-link
                :to="{ name: 'Room', params: { id: networkingRoom.id } }"
                class="btn btn-primary rounded-0"
                :style="{ 'background-color': bc.branding.highlightColor, 'border-color': bc.branding.highlightColor }"
                >Join Room</router-link>
              </p>
            </div>
            </div>

          </div>
        </div>
      </div>

      <!----

              SCHEDULE UNIT (TABLE)

      ----->

      <h4 class="mt-5 text-center">Live Stream Schedule</h4>

      <div class="schedule-unit-wrapper container mt-3 " v-if="bc.schedule && bc.schedule.scheduleType == 'table'">
        <div class="primary-top-extra"></div>
        <div class="container schedule-unit">
          <div class="row p-2 pt-3 p-md-5 border justify-content-between">
            <div class="col-md-3">
              <h2 :style="{'color':bc.branding.colourTextHeading}" class="my-2">{{bc.schedule.scheduleTitle}}</h2>
              <p class="small" v-html="bc.schedule.scheduleSubtitle"></p>
              <p class="mt-3"><router-link to="/speakers" class="btn btn-primary rounded-0 text-white" >View Speakers</router-link></p>
            </div>
            <div class="col-md-8" >
              <div v-for="scheduleItem in bc.schedule.scheduleItems" :key="scheduleItem.dateTime">
                <div class="row">
                  <div class="col-1">
                      <p :style="{'color':bc.branding.colourTextHeading}" class="pr-1 small mt-0">{{ scheduleItem.dateTime }}</p>
                  </div>
                  <div class="col">
                 <h6 :style="{'color':bc.branding.colourTextHeading}"> {{scheduleItem.title}}</h6>
                  <p class="small mt-1">{{scheduleItem.description}}</p>
                  <table class="table">
                      <tr v-for="speakerItem in scheduleItem.speakers" :key="speakerItem.title">
                        <td :style="{'color':bc.branding.colourPrimary}">{{speakerItem.time}}</td>
                        <td><p class="mb-0" :style="{'color':bc.branding.colourTextHeading}"><b>{{speakerItem.title}}</b></p><p class="my-0"><small>{{speakerItem.shortDescription}}</small></p></td>
                      </tr>
                  </table>
                  </div>
                </div>
      
              </div>
            </div>
          </div>
        </div>
      </div>


      
      <div class="bg-py-5" v-if="bc.schedule && bc.schedule.scheduleType == 'splitDay'">
        <div class="schedule-unit-wrapper container" v-for="scheduleItem in bc.schedule.scheduleItems" :key="scheduleItem.dateTime">
          <div class="container mt-3 schedule-unit">
            <div class="row p-2 pt-3 p-md-3 border justify-content-between bg-white"  v-on:click="scheduleItem.expand = !scheduleItem.expand">
              <div class="col-md-2">
                <h4 :style="{'color':bc.branding.colourTextHeading}" class="mt-0 mb-0"><b>{{scheduleItem.title}}</b></h4>
                <p class="mt-1"  v-if="scheduleItem.expand || scheduleItem.date == dateToday">{{ scheduleItem.dateTime }}</p>
              </div>
              <div class="col-md-9">
                <div class="mt-4" v-if="scheduleItem.expand || scheduleItem.date == dateToday">
                  <h5 :style="{'color':bc.branding.colourPrimary}" class="mt-0 mb-1">Sessions</h5>
                  <div class="row mb-2">
                    <div class="col-6 col-md-3" v-for="speakerItem in scheduleItem.speakers" :key="speakerItem.title" width="100%">
                      <p class="small mt-1 mb-0">{{speakerItem.time}}</p>
                      <h6 class="mb-0 mt-0">{{speakerItem.title}}</h6>
                      <p class="small mt-0">{{speakerItem.shortDescription}}</p>
                    </div>
                  </div>
                  <div class="mt-4 text-right" v-if="scheduleItem.expand || scheduleItem.date == dateToday">
                  <a style="color:#BC8B2C;cursor:pointer;font-size:0.7em;font-weight:bold;text-decoration:underline;" class="mt-0 mb-1">HIDE SCHEDULE</a>
                </div>
                </div>
                <div class="mt-4 text-right" v-if="!scheduleItem.expand && scheduleItem.date != dateToday">
                  <a style="color:#BC8B2C;cursor:pointer;font-size:0.7em;font-weight:bold;text-decoration:underline;" class="mt-0 mb-1">SHOW SCHEDULE +</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <div
      class="schedule-unit-wrapper container"
      v-if="bc.schedule && !bc.schedule.scheduleType"
    >
      <div class="container my-3 schedule-unit">
        <div class="row p-2 pt-3 p-md-3 border justify-content-between">
          <div class="col-md-2">
            <h3 :style="{ color: bc.branding.colourTextHeading }" class="my-2">
              {{ bc.schedule.scheduleTitle }}
            </h3>
            <p>{{ bc.schedule.scheduleSubtitle }}</p>
          </div>
          <div class="col-md-9">
            <div
              v-for="scheduleItem in bc.schedule.scheduleItems"
              :key="scheduleItem.dateTime"
            >
              <h4 :style="{ color: bc.branding.colourPrimary }" class="mt-0">
                {{ scheduleItem.dateTime }}<br />
                {{ scheduleItem.title }}
              </h4>
              <div class="row mb-4">
                <div
                  class="col-6 col-md-3"
                  v-for="speakerItem in scheduleItem.speakers"
                  :key="speakerItem.title"
                  width="100%"
                >
                  <img
                    :src="speakerItem.thumbnail"
                    :alt="speakerItem.alt"
                    width="100%"
                  />
                  <h6 class="mt-3 mb-2">{{ speakerItem.title }}</h6>
                  <p class="small mt-0">{{ speakerItem.shortDescription }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 ">
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 col-md text-center"
            v-for="sponsorItem in bc.partners"
            :key="sponsorItem.name"
          >
            <img
              :src="sponsorItem.image"
              :alt="sponsorItem.name"
              width="80%"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="my-5 ">
      <h4 class="text-center">Sponsors</h4>
      <div class="container">
        <div class="row justify-content-center">
          <div
            class="col-4 col-md text-center"
            v-for="sponsorItem in bc.sponsors"
            :key="sponsorItem.name"
          >
            <img
              :src="sponsorItem.image"
              :alt="sponsorItem.name"
              width="80%"
            />
          </div>
        </div>
      </div>
    </div>

    <h4 class="text-center">Useful Links</h4>
    <p class="text-center"><a href="https://www.afdb.org/en/cop27"  :style="{ color: bc.branding.colourTextHeading }" >African Development Bank @COP27</a></p>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Lobby',
  components: {
  },
  computed: {
    bc() {
      return this.$store.state.bc
    },
    attendeeName(){
      return this.$store.state.attendeeName
    },
    playerRooms() {
      return this.$store.getters.playerRooms
    },
    networkingRooms() {
      return this.$store.getters.networkingRooms
    },
    zoomRooms() {
      return this.$store.getters.assignedZoomRooms
    },
  },
  mounted: function () {

  },
}
</script>

<style lang="scss">
  .primary-top {

    //border-top:5px solid #000;
  }
  /*
  .primary-top-extra {
    padding:5px; 
    background:linear-gradient(90deg, rgba(227, 25, 55, 1) 0%, rgba(168, 36, 101, 1) 60%, rgba(82, 54, 171, 1) 100%)
  }
  */
  #route p a[target="_blank"]::after {
    content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==);
    margin: 0 3px 0 5px;
  }
  @keyframes pulse {
  0%   { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
  }

  .live-badge {
    font-size:12px;
    padding:3px 10px;
    animation: pulse 3s infinite;
    transform:translateY(-2px);
  }
  .rewatch-badge {
    font-size:12px;
    padding:3px 10px;
    transform:translateY(-2px);
  }
  .main-subtitle {
    font-size: 1.2rem;
    line-height: 1.5;
  }
  .schedule-unit h2, h6 {
    font-weight:bold;
  }
  .schedule-unit-wrapper {
    padding:0 15px;
    h3 {
      line-height:1.2 !important;
    }
    h6 {
      line-height:1.2 !important;
    }
  }
  .rooms-zoom {
    h5 {
      line-height: 1.2 !important;
    }
  }
  .hero-tile {
    width:100%;
  }

  .video-wrapper {
  width: 100%;
  padding-top: 56.25%;
  position: relative;
  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
</style>
