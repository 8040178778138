<template>
  <div class="home">
    <div class="welcome">
      <div>
        <div class="loaders">
          <div
            class="loader-state loader"
            :style="{ color: bc.branding.colourTextHeading }"
            v-bind:class="{ fade: loadFade }"
          ></div>
          <div class="loader-state success-checkmark" v-if="loadFade">
            <div class="check-icon">
              <span class="icon-line line-tip"></span>
              <span class="icon-line line-long"></span>
              <div class="icon-circle"></div>
              <div class="icon-fix"></div>
            </div>
          </div>
        </div>

        <div class="messages">
          <h4
            :style="{ color: bc.branding.colourTextHeading }"
            class="message1"
          >
            Welcome to COP27 - Africa Pavillion
          </h4>
          <h4
            :style="{ color: bc.branding.colourTextHeading }"
            class="message2"
          >
            Checking you in to the event...
          </h4>
          <h4
            :style="{ color: bc.branding.colourTextHeading }"
            class="message3"
          >
            Connecting you to the platform...
          </h4>
          <h4
            :style="{ color: bc.branding.colourTextHeading }"
            class="message4"
          >
            Connecting you to the live stream...
          </h4>
          <h4
            :style="{ color: bc.branding.colourTextHeading }"
            class="message5"
          >
            You're all set. Enjoy the event!
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {
      loadFade: false,
    };
  },
  computed: {
    bc() {
      return this.$store.state.bc;
    },
    playerRooms() {
      return this.$store.getters.playerRooms;
    },
    networkingRooms() {
      return this.$store.getters.networkingRooms;
    },
  },
  mounted: function () {
    if (this.bc.skipIntro){
      this.$router.push({ path: "/stage/highlights-stage" });
    } else {
      setTimeout(() => {
        this.loadFade = true;
      }, 8000);

      setTimeout(() => {
        this.$router.push({ path: "/stage/highlights-stage" });
      }, 10000);
    }
  },
};
</script>

<style lang="scss">
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.welcome {
  text-align: center;
  padding-top: 100px;
}

.loader {
  opacity: 1;
  transition: opacity 0.2s;
  &.fade {
    opacity: 0;
  }
}

.messages {
  position: relative;
  padding-top: 50px;
  h4 {
    position: absolute;
    width: 100%;
    padding:0 20px;
    text-align: center;
    opacity: 0;
    &.message1 {
      animation: fadeIn 2s;
    }
    &.message2 {
      animation: fadeIn 2s;
      animation-delay: 2s;
    }
    &.message3 {
      animation: fadeIn 2s;
      animation-delay: 4s;
    }
    &.message4 {
      animation: fadeIn 2s;
      animation-delay: 6s;
    }
    &.message5 {
      animation: fadeIn 2s;
      animation-delay: 8s;
    }
  }
}

/**
 * Extracted from: SweetAlert
 * Modified by: Istiak Tridip
 */
.success-checkmark {
  width: 80px;
  height: 115px;
  margin: -100px auto 0 auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::before {
      top: 3px;
      left: -2px;
      width: 30px;
      transform-origin: 100% 50%;
      border-radius: 100px 0 0 100px;
    }

    &::after {
      top: 0;
      left: 30px;
      width: 60px;
      transform-origin: 0 50%;
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      top: 8px;
      width: 5px;
      left: 26px;
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }
}

@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes icon-line-long {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}
</style>