<template>
  <div class="home">

    <div class="container mt-3 mb-2 pt-3 pb-2" v-if="room">
      <div class="row">
        <div class="col">
          <router-link to="/lobby" :style="{'color':bc.branding.colourTextHeading}" >&laquo; Back to Lobby</router-link>
          <h4 class="text-center mt-3" :style="{'color':bc.branding.colourTextHeading}">{{room.title}}</h4>
          <p v-if="room.joinFrom" class="text-center small"><b>Starting at</b><br> {{room.joinFrom}}</p>
         </div>
      </div>
    </div>

    <div class="container">
    <iframe class="third-party-iframe" :src="thirdPartyIframeUrl" frameBorder="0" scrolling="no" allowFullScreen v-if="thirdPartyIframeUrl"></iframe>
    </div>
    <div class="container room-section">
      <div class="loading" v-if="loading">
        <div>
          <div class="loader" :style="{'color':bc.branding.colourTextHeading}"></div>
          <div class="message">
            <h4 :style="{'color':bc.branding.colourTextHeading}">Connecting you to networking...</h4>
          </div>
        </div>
      </div>
      <iframe :src=iframeURL
      allow="camera; microphone; fullscreen; speaker; display-capture"
      style="border:none !important;"
      frameborder="0"
      ></iframe>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Room',
  data() {
    return {
      iframeURL: '',
      loading: true
    };
  },
  computed: {
    bc() {
      return this.$store.state.bc
    },
    ticketId() {
      return this.$store.state.ticketId
    },
    room() {
      return this.$store.getters.getRoomById(this.$route.params.id);
    },
    userIsAdmin() {
      if (this.room.host_ticket_ids.includes(this.ticketId)){
        return true;
      }
      return false;
    },
    thirdPartyIframeUrl() {
      if (this.room.thirdPartyIframeUrl){
        return this.room.thirdPartyIframeUrl;
      }
      return null;
    }
  },
  mounted: function () {
    this.setIframeURL();
    setTimeout(() => {
      this.loading = false;
    }, 2500);
  },
  watch: {

  },
  methods: {
    setIframeURL() {
      if (this.userIsAdmin){
        this.iframeURL = this.room.whereby_host;
      } else {
        this.iframeURL = this.room.whereby_attendee;
      }
    }
  }
}
</script>

<style lang="scss">
    .room-section {
      position:relative;
      margin:10px auto;
      .loading {
        position:absolute;
        top:0;
        left:0;
        background:#f9f9f9;
        width:100%;
        height:50vh;
        min-height:500px;
        display:flex;
        justify-content: center;
        padding-top:100px;
        max-height:750px;
        border-radius:15px;
      }
      iframe {
        width:100%;
        height:50vh;
        min-height:500px;
        max-height:650px;
        border-radius:15px;
      }
    }
    .third-party-iframe {
      width:100%;
      display:block;
      margin:20px auto;
      height:350px;
      border-radius:15px;
      box-shadow: 10px 10px 51px -34px rgba(0,0,0,0.55);
    }


</style>
