import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Lobby from '../views/Lobby.vue'
import Room from '../views/Room.vue'
import ZoomRoom from '../views/ZoomRoom.vue'
import Stage from '../views/Stage.vue'
import Speakers from '../views/Speakers.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    props: route => (console.log(route))
  },
  {
    path: '/lobby',
    name: 'Lobby',
    component: Lobby
  },
  {
    path: '/speakers',
    name: 'Speakers',
    component: Speakers
  },
  {
    path: '/room/:id',
    name: 'Room',
    component: Room
  },
  {
    path: '/zoom-room/:id',
    name: 'ZoomRoom',
    component: ZoomRoom
  },
  ,
  {
    path: '/stage/:id',
    name: 'Stage',
    component: Stage
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})


export default router
