<template>
  <div class="home bg-light pb-5">

    <div class="container pt-4" v-if="room">
      <router-link to="/lobby" class="text-primary">&laquo; Back to Lobby</router-link>
      <div class="row mt-3">
        <div class="col-md-6 align-items-center d-flex">
            <h4 class="text-center mt-0" :style="{'color':bc.branding.colourTextHeading}">{{room.title}}</h4>
        </div>
        <div class="col-md-6 align-items-center">
            <div class="bg-white border p-4" v-if="showJoinZoom">
                <div class="row">
                    <div class="col-md-8">
                        <h4 style="line-height:1.1 !important;" class="m-0">Join the discussion</h4>
                        <p class="mt-0 small mb-0" style="line-height:1;">This will open zoom in a new window</p>
                    </div>
                    <div class="col-md-4 text-right">
                        <a class="mt-1 btn btn-primary rounded-0 text-white" :href="zoomURL" target="_blank" v-on:click="stopVideo()">Join the Zoom</a>
                    </div>
                </div>
            </div>
            <div class="bg-white border p-4 mt-2" v-if="showQuestionWidget">
                <div class="row" v-if="!questionSubmitted">
                    <div class="col-md-12">
                        <h4 style="line-height:1.1 !important;" class="m-0">Ask a question</h4>
                        <label for="question" class="mt-0 small mb-0" style="line-height:1;">Send a question to the panel.</label>
                        <input type="text" class="my-2 w-100 form-control rounded-0" placeholder="Type your question here"  v-model="userQuestion" v-on:keyup="detectFormSubmit">
                        <input type="submit" class="btn btn-primary rounded-0 text-white" value="Submit" @click="submitQuestion">
                    </div>
                </div>
                  <div class="row" v-if="questionSubmitted">
                    <div class="col-md-12">
                        <h4 style="line-height:1.1 !important;" class="m-0">Thank you.</h4>
                        <label for="question" class="mt-0 small mb-0" style="line-height:1;">Your question was sent to the panel.</label>
                    </div>
               
                </div>
                
            </div>
        </div>
      </div>
      <div class="mt-4 row justify-content-center">
          <div class="col-md-10 ">
            <div class="video-wrapper">
              <AwsPlayer :streamUrl="streamURL" />
            </div>
          </div>
      </div>
    </div>
    <div class="p-5"></div>
  </div>
</template>

<script>
import AwsPlayer from "../components/video-player/aws-player.vue";
import { sendForm } from "../services/formbackend";
 
export default {
  name: 'ZoomRoom',
  data() {
    return {
      iframeURL: '',
      userQuestion: '',
      loading: true
    };
  },
  components: { AwsPlayer },
  computed: {
    bc() {
      return this.$store.state.bc
    },
    ticketId() {
      return this.$store.state.ticketId
    },
    room() {
      return this.$store.getters.getRoomById(this.$route.params.id);
    },
    streamURL() {
      return this.room.streamSource;
    },
    zoomURL() {
      return this.room.zoomLink;
    },
    showJoinZoom() {
      if(this.$store.state.orderTickets.includes(this.room.showJoinZoomForTicket) || this.$store.state.orderTickets.includes('aaa-pass')){
        return true
      }
      return false;
    },
    showQuestionWidget() {
      if(this.$store.state.orderTickets.includes(this.room.showQuestionForTicket) || this.$store.state.orderTickets.includes('aaa-pass')){
        return true
      }
      return false;
    }
  },
  mounted: function () {
    this.setupPlayer();
  }, 
  watch: {

  },
  methods: {
    setupPlayer() {
      console.log("Setting up player");
    },
    detectFormSubmit(e) {
      if (e.keyCode === 13) {
        this.submitQuestion();
      }
    },
    async submitQuestion() {
      this.$gtag.event("widget", { method: "Question" });
      if (!this.userQuestion) return;
      const formId = "ef6d9d96828edba0";
      const payload = {
        question: this.userQuestion,
        stage: this.room.title,
        name: this.$store.state.attendeeName,
      };
      try {
        await sendForm({ formId, payload });
        this.questionSubmitted = true;
        this.userQuestion = "";
      } catch (error) {
        this.questionSubmitted = false;
      }
    },
    stopVideo() {
       var vid = document.getElementById("video-player");
       vid.pause();
    }
  }
}
</script>

<style lang="scss">
    .room-section {
      position:relative;
      margin:10px auto;
      .loading {
        position:absolute;
        top:0;
        left:0;
        background:#f9f9f9;
        width:100%;
        height:70vh;
        min-height:500px;
        display:flex;
        justify-content: center;
        padding-top:100px;
        max-height:750px;
        border-radius:15px;
      }
      iframe {
        width:100%;
        height:70vh;
        min-height:500px;
        max-height:750px;
        border-radius:15px;
      }
    }


</style>