

import axios from 'axios'
const API_BASE_URL = 'https://www.formbackend.com/f/'

const apiClient = axios.create({
  baseURL: API_BASE_URL,
  timeout: 10000,
  headers: {},
})

export const sendForm = async ({ formId, payload }) => {
  console.log('%c sending form data....', 'background: #bada55; color: #222', payload)
  const resp = await apiClient.post(`${formId}`, payload)
  return resp.data
}